.select-section {
    margin: 25px;
    width: 550px;
}

.select-section > h2{
    padding: 15px;
    text-align: center;
}

.button-container {
    border-radius: 45px;
    display:flex;
    align-items: center;
    height: 550px;
    padding: 15px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.button-container > .btn {
    width: 150px;
    margin: 20px 0 20px 0;
}

.section-label {
    width: 550px;
    text-align: center;
}