.home-roulette-container {
    height: auto;
    width:min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 45px;
    padding: 10px;
}

.roulette-container{
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    border-radius: 45px;
    padding: 50px;
}


.select-roulette {
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 600px;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

text {
    font-size: 2em;
    width: 100%;
    text-align: center;
  }

#hand-gif{
    transform:rotate(150deg) scaleY(-1) scale(1);
    width: 50%;
    height: 200px;
    margin: 10px;
  }

@media (max-width: 1180px) {
    .select-roulette{
        display: none;
    }
}
