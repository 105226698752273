.home-container {
  height: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  border-radius: 45px;
  padding: 10px;
  /* min-width: 600px; */
}

.back-home {
  transform: scale(1.5);
  margin: 70px !important;
}

.home-btns {
  transform: scale(1.5);
  width: 150px;
  height: 50px;
  margin: 10px;
}

.btn {
  margin: 10px;
  box-shadow: -1px 1px rgb(0, 0, 0);
}

.link a {
  margin: 0px 100px 50px 100px;
}

.link {
  display: flex;
  flex-direction: row;
  align-content: center;
}

@media (max-width: 830px) {
  .link {
    flex-direction: column;
  }
  .hand-gif {
    display: none;
  }
  .hand-gif-2 {
    display: none;
  }
  .welcome-message {
    margin-bottom: 50px !important;
  }
}

.welcome-message {
  text-align: center;
  margin: 50px 50px 10px 50px;
}

.welcome-message h1 {
  font-size: 3rem;
  color: #e33d75;
  font-weight: bolder;
}

.welcome-message p {
  font-size: 1.5em;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
}

.free-play-text {
  width: 300px;
  height: 50px;
}

.play-btn-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.play-btn-section p {
  text-align: center;
}

.hand-gif {
  transform: rotate(100deg) scaleY(-1) scale(1);
  height: 200px;
  width: auto;
  margin: 10px;
}

.hand-gif-2 {
  transform: rotate(80deg) scale(1);
  height: 200px;
  width: auto;
  margin: 10px;
}
