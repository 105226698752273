.look-section {
  margin: 25px;
}

.look-container {
  border-radius: 45px;
  display: flex;
  flex-direction: column;
  width: 550px;
  align-items: center;
  height: 550px;
  min-height: 550px;
  padding: 15px;
  justify-content: center;
}

.look-label {
  width: 100%;
  text-align: center;
}

hr {
  color: #fa9a8580;
  opacity: 1;
}

.h6-info {
  margin-bottom: 20px;
}
