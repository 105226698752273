#wheel {
  position: relative;
  width: 550px;
  height: 550px;
}

#canvas {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}
