.svg-image {
  position: absolute;
  top: 0;
  left: 0;
}

.svg-image#browbone-sparkle {
  z-index: 2;
}

.svg-image#above-crease-sparkle {
  z-index: 3;
}

.svg-image#crease-sparkle {
  z-index: 4;
}

.svg-image#deep-crease-sparkle {
  z-index: 5;
}

.svg-image#inner-lid-sparkle {
  z-index: 6;
}

.svg-image#middle-lid-sparkle {
  z-index: 7;
}

.svg-image#outer-lid-sparkle {
  z-index: 8;
}

.svg-image#inner-corner-sparkle {
  z-index: 9;
}

#color-selectors div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 50px;
  height: 70px;
}
