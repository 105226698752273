.home-free-play-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  border-radius: 45px;
}

.free-play-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* border: 5px solid pink; */
  margin: 0 auto;
  border-radius: 45px;
}

.header {
  height: 70px;
}

.btn-primary {
  background-color: #e33d75;
  border-color: #e33d75;
  font-weight: bold;
  border-width: 3px;
  margin: 0 auto;
  border: 1px solid black;
}

.btn:disabled {
  background-color: #000;
  border-color: #000;
  color: #fff;
  opacity: 1;
}

.btn:hover {
  background-color: #000;
  border-color: #000;
}

.btn-primary:active,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff;
  border-color: #000;
  border-width: 3px;
  background-color: #000;
}

.btn-primary:focus,
.btn-primary:focus-visible {
  color: #fff;
  border-color: #000;
  border-width: 3px;
  background-color: #000;
}

.box-container {
  box-shadow: -5px 5px rgb(0, 0, 0);
  background-color: rgb(255, 190, 201);
  border: 1px solid black;
  height: 650px !important;
}

a {
  text-decoration: none;
}

@media (max-width: 501px) {
  .box-container {
    width: 90vw !important;
    margin: 0 auto !important;
  }

  .instructions,
  .palette-label,
  .look-label,
  .look-container,
  .section-label,
  .select-section,
  #color-selectors {
    width: 90vw !important;
    margin: 25px 0 25px 0 !important;
  }

  tr {
    width: 100% !important;
  }

  #canvas {
    width: 100vw;
  }

  .palette-select-box {
    overflow-x: hidden;
  }
  #palette-table {
    width: 100% !important;
  }

  .icon {
    font-size: 30px !important;
    border: none !important;
  }

  table {
    table-layout: fixed;
    table-layout: fixed; /* This property ensures equal width for each cell */
    border-collapse: collapse;
  }
  td {
    margin: 0 !important;
    height: 75px !important;
  }

  th {
    margin: 0 !important;
  }

  .home-free-play-container,
  .free-play-container,
  .roulette-container,
  .home-roulette-container {
    padding: 0;
  }
}
